import React from 'react';
import styled from 'styled-components';

const Video = styled.video`
  left: 50%;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: -5;

  @media screen and (min-aspect-ratio: 16/9) {
    width: 100vw;
    height: auto;
  }

  @media screen and (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100vh;
  }
`;

const VideoOverlay = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(black 0%, transparent 50%),
    radial-gradient(black 0%, transparent 50%);
  background-size: 3px 3px;
  height: 9000vh;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  z-index: -4;
`;

interface Props {
  video: string;
  poster: string;
}

const BgVideo: React.FC<Props> = ({ video, poster }) => (
  <>
    <Video autoPlay loop muted poster={poster}>
      <source src={video} type="video/mp4" />
    </Video>
    <VideoOverlay />
  </>
);

export default BgVideo;
